<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <template slot="title">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>{{ title }}</strong>
              <!-- <v-select
              ref="icon"
              placeholder="Select App"
              class="style-chooser"
              label="appName"
              style="width: 100px;"
              v-model="appId"
              :options="dataapplist"
              :reduce="(tes) => tes.id"
              @input="getData"
            >
          </v-select> -->
              <a-select v-model="dbname" v-if="mode === 'netral'" style="width: 150px;" placeholder="DB name"
                class="ml-2" show-search option-filter-prop="children" :filter-option="filterOption"
                @change="getTableList">
                <a-select-option v-for="(data, index) in dblist" :key="index" :value="data.dbname">{{ data.dbname + '-'
                  + data.appname }}</a-select-option>
              </a-select>
              <a-select v-model="tablename" v-if="mode === 'netral'" style="width: 150px;" class="ml-2" show-search
                option-filter-prop="children" :filter-option="filterOption" @change="getAllData">
                <a-select-option v-for="(data, index) in tablelist" :key="index" :value="data.table_name">{{
                  data.table_name }}</a-select-option>
              </a-select>
              <a-button-group class="ml-2">
              <!-- <a-button
                  v-if="dbname !== '' && tablename !== '' && mode === 'netral' || mode === 'oncreate'"
                  class="ml-2 btn btn-outline-primary" @click="showFormModal">
                  Open Form
                </a-button>
                <a-button v-if="dbname !== '' && tablename !== '' && mode === 'netral'"
                  class="btn btn-outline-danger ml-2 mr-2" @click="removeOrDelete" :disabled="mode !== 'netral'">
                  Delete
                </a-button> -->
                <a-button class="btn btn-outline-success" @click="saveChanges"
                  v-if="dbname !== '' && tablename !== '' && mode === 'onupdate'">
                  Save Changes
                </a-button>
                <a-button class="btn btn-outline-danger" @click="cancelaction"
                  v-if="dbname !== '' && tablename !== '' && mode === 'onupdate'">
                  Cancel
                </a-button>
              </a-button-group>
              <a-switch v-model="showjsonincl" class="ml-2 mr-1">
                <a-icon slot="checkedChildren" type="check" />
                <a-icon slot="unCheckedChildren" type="close" />
              </a-switch>
              <strong>Show json in console when post</strong>
            </div>
          </div>
        </template>
        <div class="row">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <a-tree class="draggable-tree" :replaceFields="{
              children: 'children',
              title: 'field',
              key: 'id',
            }" :autoExpandParent="false" :default-expanded-keys="expandedKeys" draggable :tree-data="treeData"
              @dragenter="onDragEnter" @drop="onDrop">
              <template #title="{ field, id, key: treeKey }">
                <a-dropdown :trigger="['contextmenu']">
                  <span>{{ field }}</span>
                  <template #overlay>
                    <a-menu @click="({ key: menuKey }) => onContextMenuClick(treeKey, menuKey)">
                      <a-menu-item key="1" class="text-warning" @click="showModal('update', id)">
                        <a-icon type="edit" />Edit</a-menu-item>
                      <a-menu-item key="3" class="text-danger" @click="confirmationDelete(id)">
                        <a-icon type="delete" />Delete</a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </template>
            </a-tree>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <a-tree class="draggable-tree" :replaceFields="{
              children: 'children',
              title: 'menu_name',
              key: 'id',
            }" :default-expanded-keys="expandedKeys" draggable :tree-data="modifiedTreeData" @dragenter="onDragEnter"
              @drop="onDrop" />
          </div>
        </div>
      </a-card>
    </div>
    <a-modal v-model="modalVisible">
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="modalVisible = false">Cancel</a-button>
        <a-button class="btn btn-outline-primary" @click="cloneAppData">Process</a-button>
      </template>
      <strong>To App</strong>
      <a-select class="ml-5" v-model="tujuanappid" style="width: 200px;">
        <a-select-option v-for="(data, index) in dataapplist" :key="index" :value="data.id">{{ data.appName
          }}</a-select-option>
      </a-select>
    </a-modal>
    <a-modal v-model="formvisible" title="Form Grid Config" :width="700" :dialogStyle="{ top: '10px' }">
      <template slot="footer">
        <a-button class="btn btn-outline-danger" @click="handleCancel">Cancel</a-button>
        <a-button class="btn btn-outline-primary" @click="handleSubmit">Submit</a-button>
      </template>
      <div class="row">
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
          <label class="">Editable</label>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
          <label class="">Resizeable</label>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
          <label class="">Sortable</label>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
          <label class="">Cell Hidden</label>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
          <label class="">Allow Null</label>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
          <label class="">Align Type</label>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <!-- <a-input class="" placeholder="Route" v-model="modalInput.route" ref="routeinput"></a-input> -->
          <a-switch :defaultChecked="modalInput.editable === 'true'" @change="(x) => switchChangesValue(x, 'editable', 'truefalse')">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <a-switch :defaultChecked="modalInput.resizable === 'true'" @change="(x) => switchChangesValue(x, 'resizable', 'truefalse')">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <a-switch :defaultChecked="modalInput.sortable === 'true'" @change="(x) => switchChangesValue(x, 'sortable', 'truefalse')">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <a-switch :defaultChecked="modalInput.isHide === 'true'" @change="(x) => switchChangesValue(x, 'isHide', 'truefalse')">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <a-switch :defaultChecked="modalInput.allowNull === 1" @change="(x) => switchChangesValue(x, 'allowNull', '01')">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <a-radio-group v-model="modalInput.type" size="small">
            <a-radio-button :value="null">
              <a-icon type="align-left" />
            </a-radio-button>
            <a-radio-button value="rightAligned">
              <a-icon type="align-right" />
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-1">
          <label class="">Field (Samakan dengan yang ada di database)</label>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-1">
          <label class="">Header (Yang akan tampil di client)</label>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <a-input class="" placeholder="Samakan dengan yang ada di database" v-model="modalInput.field" ref="fieldinput"></a-input>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <a-input class="" placeholder="Yang akan tampil di client" v-model="modalInput.headerName" ref="headerNameinput"></a-input>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
          <label class="">Cell Editor</label>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
          <label class="">Value Formatter</label>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
          <label class="">Data Type</label>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
          <v-select
            :clearable="true"
            class="style-chooser"
            placeholder="Cell Input Editor"
            style="width: 100%"
            label="keterangan"
            v-model="modalInput.cellEditor"
            :options="cellEditorList"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
          <v-select
            :clearable="true"
            class="style-chooser"
            placeholder="Format value"
            style="width: 100%"
            label="keterangan"
            v-model="modalInput.valueFormatter"
            :options="valueFormatterList"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
          <v-select
            :clearable="false"
            class="style-chooser"
            placeholder="Tipe Data"
            style="width: 100%"
            label="keterangan"
            v-model="modalInput.data_type"
            :options="data_typeList"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <label class="">Table Ref</label>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
          <label class="">Table Ref Return Field</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
          <v-select
            :clearable="true"
            class="style-chooser"
            placeholder="Ref Table"
            style="width: 100%"
            label="table_name"
            v-model="modalInput.reftable"
            :options="tablelist"
            :reduce="(tes) => tes.table_name"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <v-select
            :clearable="true"
            class="style-chooser"
            placeholder="Ref Table Field Return"
            style="width: 100%"
            label="keterangan"
            v-model="modalInput.retreffield"
            :options="retreffieldList"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
          <label class="">Filter in View</label>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
          <label class="">Min Width</label>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
          <label class="">Max Width</label>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
          <label class="">Width</label>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <v-select
            :clearable="false"
            class="style-chooser"
            placeholder="Filter"
            style="width: 100%"
            label="keterangan"
            v-model="modalInput.filter"
            :options="filterList"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <a-input class="" placeholder="minWidth" v-model="modalInput.minWidth" ref="minWidthinput"></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <a-input class="" placeholder="maxWidth" v-model="modalInput.maxWidth" ref="maxWidthinput"></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <a-input class="" placeholder="width" v-model="modalInput.width" ref="widthinput"></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
          <label class="">Urutan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <label class="">Pinned</label>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <a-input class="" placeholder="Urutan" v-model="modalInput.urutan" ref="urutaninput"></a-input>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
          <a-radio-group v-model="modalInput.pinned" size="small">
            <a-radio-button value="false">
              <a-icon type="close" />
            </a-radio-button>
            <a-radio-button value="left">
              <a-icon type="caret-left" />
            </a-radio-button>
            <a-radio-button value="right">
              <a-icon type="caret-right" />
            </a-radio-button>
          </a-radio-group>
        </div>
      </div>
    </a-modal>

    <a-modal v-model="selectIconModalVisible" :dialogStyle="{ top: '10px' }" :footer="null">
      <a-card>
        <template slot="title">
          <a-input class="" placeholder="Cari Icon" v-model="iconModalSearchInput" @input="filterIcon"></a-input>
        </template>
        <a-card-grid
          style="width:25%;text-align:center; cursor: pointer;white-space: nowrap;overflow: hidden;text-overflow: ellipsis; "
          v-for="(d, i) in iconData" :key="i" @click="selectIcon(d)">
          <i :class="d.icon" style="font-size: 25px;"></i>
          <br>
          <!-- <center> -->
          <strong style="font-size: 11px;">{{ d.icon }}</strong>
          <!-- </center> -->
        </a-card-grid>
      </a-card>
    </a-modal>
    <myform ref="myform" />
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { Modal } from 'ant-design-vue'

import myform from './form'
// import { mapState } from 'vuex'
export default {
  components: {
    myform,
  },
  created() {
    this.getMaster()
    // this.getData()
  },
  data() {
    return {
      appname: '',
      dbname: '',
      tablename: '',
      applist: [],
      dblist: [],
      tablelist: [],
      title: 'Urutan Grid Config',
      mode: 'netral',
      appId: null,
      tujuanappid: null,
      dataapplist: [],
      treeData: [],
      backupTreeData: [],
      modifiedTreeData: [],
      modifiedTreeDataClone: [],
      changedData: [],
      expandedKeys: ['0-0', '0-0-0', '0-0-0-0'],
      showjsonincl: false,
      modalVisible: false,

      modalaction: 'create',
      pagesList: [],
      filterList: [
        {
          keterangan: 'Non Active',
          kode: 'false',
        },
        {
          keterangan: 'Normal',
          kode: 'true',
        },
        {
          keterangan: 'Date Filter',
          kode: 'agDateColumnFilter',
        },
      ],
      retreffieldList: [
        {
          keterangan: 'Kode',
          kode: 'kode',
        },
        {
          keterangan: 'ID',
          kode: 'id',
        },
      ],
      cellEditorList: [
        {
          keterangan: 'Numeric Editor',
          kode: 'numericEditor',
        },
        {
          keterangan: 'Date Editor',
          kode: 'dateEditor',
        },
      ],
      valueFormatterList: [
        {
          keterangan: 'Currency',
          kode: 'currency',
        },
        {
          keterangan: 'Date YYYY-MM-DD',
          kode: 'dateYYYY-MM-DD',
        },
      ],
      data_typeList: [
        {
          keterangan: 'varchar',
          kode: 'varchar',
        },
        {
          keterangan: 'char',
          kode: 'char',
        },
        {
          keterangan: 'date',
          kode: 'date',
        },
        {
          keterangan: 'int',
          kode: 'int',
        },
        {
          keterangan: 'bigint',
          kode: 'bigint',
        },
        {
          keterangan: 'decimal',
          kode: 'decimal',
        },
      ],
      formvisible: false,
      modalInput: {
        table_name: this.tablename,
        field: '',
        headerName: '',
        editable: 'true',
        cellEditor: null,
        valueFormatter: null,
        data_type: 'varchar',
        reftable: null,
        retreffield: null,
        filter: 'true',
        resizable: 'true',
        sortable: 'true',
        pinned: 'false',
        minWidth: '',
        maxWidth: '',
        width: '',
        isHide: '',
        rules: '',
        cellRenderer: '',
        type: '',
        allowNull: 1,
        urutan: '',
      },

      iconModalSearchInput: '',
      selectIconModalVisible: false,
      iconData: [
        { icon: 'fa fa-address-book' },
        { icon: 'fa fa-address-book-o' },
        { icon: 'fa fa-address-card' },
        { icon: 'fa fa-address-card-o' },
        { icon: 'fa fa-adjust' },
        { icon: 'fa fa-american-sign-language-interpreting' },
        { icon: 'fa fa-anchor' },
        { icon: 'fa fa-archive' },
        { icon: 'fa fa-area-chart' },
        { icon: 'fa fa-arrows' },
        { icon: 'fa fa-arrows-h' },
        { icon: 'fa fa-arrows-v' },
        { icon: 'fa fa-asl-interpreting' },
        { icon: 'fa fa-assistive-listening-systems' },
        { icon: 'fa fa-asterisk' },
        { icon: 'fa fa-at' },
        { icon: 'fa fa-automobile' },
        { icon: 'fa fa-audio-description' },
        { icon: 'fa fa-balance-scale' },
        { icon: 'fa fa-ban' },
        { icon: 'fa fa-bank' },
        { icon: 'fa fa-bar-chart' },
        { icon: 'fa fa-bar-chart-o' },
        { icon: 'fa fa-barcode' },
        { icon: 'fa fa-bars' },
        { icon: 'fa fa-bath' },
        { icon: 'fa fa-bathtub' },
        { icon: 'fa fa-battery-0' },
        { icon: 'fa fa-battery-1' },
        { icon: 'fa fa-battery-2' },
        { icon: 'fa fa-battery-3' },
        { icon: 'fa fa-battery-4' },
        { icon: 'fa fa-battery-empty' },
        { icon: 'fa fa-battery-full' },
        { icon: 'fa fa-battery-half' },
        { icon: 'fa fa-battery-quarter' },
        { icon: 'fa fa-battery-three-quarters' },
        { icon: 'fa fa-bed' },
        { icon: 'fa fa-beer' },
        { icon: 'fa fa-bell' },
        { icon: 'fa fa-bell-o' },
        { icon: 'fa fa-bell-slash' },
        { icon: 'fa fa-bell-slash-o' },
        { icon: 'fa fa-bicycle' },
        { icon: 'fa fa-binoculars' },
        { icon: 'fa fa-birthday-cake' },
        { icon: 'fa fa-blind' },
        { icon: 'fa fa-bolt' },
        { icon: 'fa fa-bomb' },
        { icon: 'fa fa-book' },
        { icon: 'fa fa-bookmark' },
        { icon: 'fa fa-bookmark-o' },
        { icon: 'fa fa-braille' },
        { icon: 'fa fa-briefcase' },
        { icon: 'fa fa-bug' },
        { icon: 'fa fa-building' },
        { icon: 'fa fa-building-o' },
        { icon: 'fa fa-bullhorn' },
        { icon: 'fa fa-bullseye' },
        { icon: 'fa fa-bus' },
        { icon: 'fa fa-cab' },
        { icon: 'fa fa-calculator' },
        { icon: 'fa fa-calendar' },
        { icon: 'fa fa-calendar-o' },
        { icon: 'fa fa-calendar-check-o' },
        { icon: 'fa fa-calendar-minus-o' },
        { icon: 'fa fa-calendar-plus-o' },
        { icon: 'fa fa-calendar-times-o' },
        { icon: 'fa fa-camera' },
        { icon: 'fa fa-camera-retro' },
        { icon: 'fa fa-car' },
        { icon: 'fa fa-caret-square-o-down' },
        { icon: 'fa fa-caret-square-o-left' },
        { icon: 'fa fa-caret-square-o-right' },
        { icon: 'fa fa-caret-square-o-up' },
        { icon: 'fa fa-cart-arrow-down' },
        { icon: 'fa fa-cart-plus' },
        { icon: 'fa fa-cc' },
        { icon: 'fa fa-certificate' },
        { icon: 'fa fa-check' },
        { icon: 'fa fa-check-circle' },
        { icon: 'fa fa-check-circle-o' },
        { icon: 'fa fa-check-square' },
        { icon: 'fa fa-check-square-o' },
        { icon: 'fa fa-child' },
        { icon: 'fa fa-circle' },
        { icon: 'fa fa-circle-o' },
        { icon: 'fa fa-circle-o-notch' },
        { icon: 'fa fa-circle-thin' },
        { icon: 'fa fa-clock-o' },
        { icon: 'fa fa-clone' },
        { icon: 'fa fa-close' },
        { icon: 'fa fa-cloud' },
        { icon: 'fa fa-cloud-download' },
        { icon: 'fa fa-cloud-upload' },
        { icon: 'fa fa-code' },
        { icon: 'fa fa-code-fork' },
        { icon: 'fa fa-coffee' },
        { icon: 'fa fa-cog' },
        { icon: 'fa fa-cogs' },
        { icon: 'fa fa-comment' },
        { icon: 'fa fa-comment-o' },
        { icon: 'fa fa-comments' },
        { icon: 'fa fa-comments-o' },
        { icon: 'fa fa-commenting' },
        { icon: 'fa fa-commenting-o' },
        { icon: 'fa fa-compass' },
        { icon: 'fa fa-copyright' },
        { icon: 'fa fa-credit-card' },
        { icon: 'fa fa-credit-card-alt' },
        { icon: 'fa fa-creative-commons' },
        { icon: 'fa fa-crop' },
        { icon: 'fa fa-crosshairs' },
        { icon: 'fa fa-cube' },
        { icon: 'fa fa-cubes' },
        { icon: 'fa fa-cutlery' },
        { icon: 'fa fa-dashboard' },
        { icon: 'fa fa-database' },
        { icon: 'fa fa-deaf' },
        { icon: 'fa fa-deafness' },
        { icon: 'fa fa-desktop' },
        { icon: 'fa fa-diamond' },
        { icon: 'fa fa-dot-circle-o' },
        { icon: 'fa fa-download' },
        { icon: 'fa fa-drivers-license' },
        { icon: 'fa fa-drivers-license-o' },
        { icon: 'fa fa-edit' },
        { icon: 'fa fa-ellipsis-h' },
        { icon: 'fa fa-ellipsis-v' },
        { icon: 'fa fa-envelope' },
        { icon: 'fa fa-envelope-o' },
        { icon: 'fa fa-envelope-open' },
        { icon: 'fa fa-envelope-open-o' },
        { icon: 'fa fa-envelope-square' },
        { icon: 'fa fa-eraser' },
        { icon: 'fa fa-exchange' },
        { icon: 'fa fa-exclamation' },
        { icon: 'fa fa-exclamation-circle' },
        { icon: 'fa fa-exclamation-triangle' },
        { icon: 'fa fa-external-link' },
        { icon: 'fa fa-external-link-square' },
        { icon: 'fa fa-eye' },
        { icon: 'fa fa-eye-slash' },
        { icon: 'fa fa-eyedropper' },
        { icon: 'fa fa-fax' },
        { icon: 'fa fa-female' },
        { icon: 'fa fa-fighter-jet' },
        { icon: 'fa fa-file-archive-o' },
        { icon: 'fa fa-file-audio-o' },
        { icon: 'fa fa-file-code-o' },
        { icon: 'fa fa-file-excel-o' },
        { icon: 'fa fa-file-image-o' },
        { icon: 'fa fa-file-movie-o' },
        { icon: 'fa fa-file-pdf-o' },
        { icon: 'fa fa-file-photo-o' },
        { icon: 'fa fa-file-picture-o' },
        { icon: 'fa fa-file-powerpoint-o' },
        { icon: 'fa fa-file-sound-o' },
        { icon: 'fa fa-file-video-o' },
        { icon: 'fa fa-file-word-o' },
        { icon: 'fa fa-file-zip-o' },
        { icon: 'fa fa-film' },
        { icon: 'fa fa-filter' },
        { icon: 'fa fa-fire' },
        { icon: 'fa fa-fire-extinguisher' },
        { icon: 'fa fa-flag' },
        { icon: 'fa fa-flag-checkered' },
        { icon: 'fa fa-flag-o' },
        { icon: 'fa fa-flash' },
        { icon: 'fa fa-flask' },
        { icon: 'fa fa-folder' },
        { icon: 'fa fa-folder-o' },
        { icon: 'fa fa-folder-open' },
        { icon: 'fa fa-folder-open-o' },
        { icon: 'fa fa-frown-o' },
        { icon: 'fa fa-futbol-o' },
        { icon: 'fa fa-gamepad' },
        { icon: 'fa fa-gavel' },
        { icon: 'fa fa-gear' },
        { icon: 'fa fa-gears' },
        { icon: 'fa fa-genderless' },
        { icon: 'fa fa-gift' },
        { icon: 'fa fa-glass' },
        { icon: 'fa fa-globe' },
        { icon: 'fa fa-graduation-cap' },
        { icon: 'fa fa-group' },
        { icon: 'fa fa-hard-of-hearing' },
        { icon: 'fa fa-hdd-o' },
        { icon: 'fa fa-handshake-o' },
        { icon: 'fa fa-hashtag' },
        { icon: 'fa fa-headphones' },
        { icon: 'fa fa-heart' },
        { icon: 'fa fa-heart-o' },
        { icon: 'fa fa-heartbeat' },
        { icon: 'fa fa-history' },
        { icon: 'fa fa-home' },
        { icon: 'fa fa-hotel' },
        { icon: 'fa fa-hourglass' },
        { icon: 'fa fa-hourglass-1' },
        { icon: 'fa fa-hourglass-2' },
        { icon: 'fa fa-hourglass-3' },
        { icon: 'fa fa-hourglass-end' },
        { icon: 'fa fa-hourglass-half' },
        { icon: 'fa fa-hourglass-o' },
        { icon: 'fa fa-hourglass-start' },
        { icon: 'fa fa-i-cursor' },
        { icon: 'fa fa-id-badge' },
        { icon: 'fa fa-id-card' },
        { icon: 'fa fa-id-card-o' },
        { icon: 'fa fa-image' },
        { icon: 'fa fa-inbox' },
        { icon: 'fa fa-industry' },
        { icon: 'fa fa-info' },
        { icon: 'fa fa-info-circle' },
        { icon: 'fa fa-institution' },
        { icon: 'fa fa-key' },
        { icon: 'fa fa-keyboard-o' },
        { icon: 'fa fa-language' },
        { icon: 'fa fa-laptop' },
        { icon: 'fa fa-leaf' },
        { icon: 'fa fa-legal' },
        { icon: 'fa fa-lemon-o' },
        { icon: 'fa fa-level-down' },
        { icon: 'fa fa-level-up' },
        { icon: 'fa fa-life-bouy' },
        { icon: 'fa fa-life-buoy' },
        { icon: 'fa fa-life-ring' },
        { icon: 'fa fa-life-saver' },
        { icon: 'fa fa-lightbulb-o' },
        { icon: 'fa fa-line-chart' },
        { icon: 'fa fa-location-arrow' },
        { icon: 'fa fa-lock' },
        { icon: 'fa fa-low-vision' },
        { icon: 'fa fa-magic' },
        { icon: 'fa fa-magnet' },
        { icon: 'fa fa-mail-forward' },
        { icon: 'fa fa-mail-reply' },
        { icon: 'fa fa-mail-reply-all' },
        { icon: 'fa fa-male' },
        { icon: 'fa fa-map' },
        { icon: 'fa fa-map-o' },
        { icon: 'fa fa-map-pin' },
        { icon: 'fa fa-map-signs' },
        { icon: 'fa fa-map-marker' },
        { icon: 'fa fa-meh-o' },
        { icon: 'fa fa-microchip' },
        { icon: 'fa fa-microphone' },
        { icon: 'fa fa-microphone-slash' },
        { icon: 'fa fa-minus' },
        { icon: 'fa fa-minus-circle' },
        { icon: 'fa fa-minus-square' },
        { icon: 'fa fa-minus-square-o' },
        { icon: 'fa fa-mobile' },
        { icon: 'fa fa-mobile-phone' },
        { icon: 'fa fa-money' },
        { icon: 'fa fa-moon-o' },
        { icon: 'fa fa-mortar-board' },
        { icon: 'fa fa-motorcycle' },
        { icon: 'fa fa-mouse-pointer' },
        { icon: 'fa fa-music' },
        { icon: 'fa fa-navicon' },
        { icon: 'fa fa-newspaper-o' },
        { icon: 'fa fa-object-group' },
        { icon: 'fa fa-object-ungroup' },
        { icon: 'fa fa-paint-brush' },
        { icon: 'fa fa-paper-plane' },
        { icon: 'fa fa-paper-plane-o' },
        { icon: 'fa fa-paw' },
        { icon: 'fa fa-pencil' },
        { icon: 'fa fa-pencil-square' },
        { icon: 'fa fa-pencil-square-o' },
        { icon: 'fa fa-percent' },
        { icon: 'fa fa-phone' },
        { icon: 'fa fa-phone-square' },
        { icon: 'fa fa-photo' },
        { icon: 'fa fa-picture-o' },
        { icon: 'fa fa-pie-chart' },
        { icon: 'fa fa-plane' },
        { icon: 'fa fa-plug' },
        { icon: 'fa fa-plus' },
        { icon: 'fa fa-plus-circle' },
        { icon: 'fa fa-plus-square' },
        { icon: 'fa fa-plus-square-o' },
        { icon: 'fa fa-podcast' },
        { icon: 'fa fa-power-off' },
        { icon: 'fa fa-print' },
        { icon: 'fa fa-puzzle-piece' },
        { icon: 'fa fa-qrcode' },
        { icon: 'fa fa-question' },
        { icon: 'fa fa-question-circle' },
        { icon: 'fa fa-question-circle-o' },
        { icon: 'fa fa-quote-left' },
        { icon: 'fa fa-quote-right' },
        { icon: 'fa fa-random' },
        { icon: 'fa fa-recycle' },
        { icon: 'fa fa-refresh' },
        { icon: 'fa fa-registered' },
        { icon: 'fa fa-remove' },
        { icon: 'fa fa-reorder' },
        { icon: 'fa fa-reply' },
        { icon: 'fa fa-reply-all' },
        { icon: 'fa fa-retweet' },
        { icon: 'fa fa-road' },
        { icon: 'fa fa-rocket' },
        { icon: 'fa fa-rss' },
        { icon: 'fa fa-rss-square' },
        { icon: 'fa fa-s15' },
        { icon: 'fa fa-search' },
        { icon: 'fa fa-search-minus' },
        { icon: 'fa fa-search-plus' },
        { icon: 'fa fa-send' },
        { icon: 'fa fa-send-o' },
        { icon: 'fa fa-server' },
        { icon: 'fa fa-share' },
        { icon: 'fa fa-share-alt' },
        { icon: 'fa fa-share-alt-square' },
        { icon: 'fa fa-share-square' },
        { icon: 'fa fa-share-square-o' },
        { icon: 'fa fa-shield' },
        { icon: 'fa fa-ship' },
        { icon: 'fa fa-shopping-bag' },
        { icon: 'fa fa-shopping-basket' },
        { icon: 'fa fa-shopping-cart' },
        { icon: 'fa fa-shower' },
        { icon: 'fa fa-sign-in' },
        { icon: 'fa fa-sign-out' },
        { icon: 'fa fa-sign-language' },
        { icon: 'fa fa-signal' },
        { icon: 'fa fa-signing' },
        { icon: 'fa fa-sitemap' },
        { icon: 'fa fa-sliders' },
        { icon: 'fa fa-smile-o' },
        { icon: 'fa fa-snowflake-o' },
        { icon: 'fa fa-soccer-ball-o' },
        { icon: 'fa fa-sort' },
        { icon: 'fa fa-sort-alpha-asc' },
        { icon: 'fa fa-sort-alpha-desc' },
        { icon: 'fa fa-sort-amount-asc' },
        { icon: 'fa fa-sort-amount-desc' },
        { icon: 'fa fa-sort-asc' },
        { icon: 'fa fa-sort-desc' },
        { icon: 'fa fa-sort-down' },
        { icon: 'fa fa-sort-numeric-asc' },
        { icon: 'fa fa-sort-numeric-desc' },
        { icon: 'fa fa-sort-up' },
        { icon: 'fa fa-space-shuttle' },
        { icon: 'fa fa-spinner' },
        { icon: 'fa fa-spoon' },
        { icon: 'fa fa-square' },
        { icon: 'fa fa-square-o' },
        { icon: 'fa fa-star' },
        { icon: 'fa fa-star-half' },
        { icon: 'fa fa-star-half-empty' },
        { icon: 'fa fa-star-half-full' },
        { icon: 'fa fa-star-half-o' },
        { icon: 'fa fa-star-o' },
        { icon: 'fa fa-sticky-note' },
        { icon: 'fa fa-sticky-note-o' },
        { icon: 'fa fa-street-view' },
        { icon: 'fa fa-suitcase' },
        { icon: 'fa fa-sun-o' },
        { icon: 'fa fa-support' },
        { icon: 'fa fa-tablet' },
        { icon: 'fa fa-tachometer' },
        { icon: 'fa fa-tag' },
        { icon: 'fa fa-tags' },
        { icon: 'fa fa-tasks' },
        { icon: 'fa fa-taxi' },
        { icon: 'fa fa-television' },
        { icon: 'fa fa-terminal' },
        { icon: 'fa fa-thermometer' },
        { icon: 'fa fa-thermometer-0' },
        { icon: 'fa fa-thermometer-1' },
        { icon: 'fa fa-thermometer-2' },
        { icon: 'fa fa-thermometer-3' },
        { icon: 'fa fa-thermometer-4' },
        { icon: 'fa fa-thermometer-empty' },
        { icon: 'fa fa-thermometer-full' },
        { icon: 'fa fa-thermometer-half' },
        { icon: 'fa fa-thermometer-quarter' },
        { icon: 'fa fa-thermometer-three-quarters' },
        { icon: 'fa fa-thumb-tack' },
        { icon: 'fa fa-thumbs-down' },
        { icon: 'fa fa-thumbs-o-up' },
        { icon: 'fa fa-thumbs-up' },
        { icon: 'fa fa-ticket' },
        { icon: 'fa fa-times' },
        { icon: 'fa fa-times-circle' },
        { icon: 'fa fa-times-circle-o' },
        { icon: 'fa fa-times-rectangle' },
        { icon: 'fa fa-times-rectangle-o' },
        { icon: 'fa fa-tint' },
        { icon: 'fa fa-toggle-down' },
        { icon: 'fa fa-toggle-left' },
        { icon: 'fa fa-toggle-right' },
        { icon: 'fa fa-toggle-up' },
        { icon: 'fa fa-toggle-off' },
        { icon: 'fa fa-toggle-on' },
        { icon: 'fa fa-trademark' },
        { icon: 'fa fa-trash' },
        { icon: 'fa fa-trash-o' },
        { icon: 'fa fa-tree' },
        { icon: 'fa fa-trophy' },
        { icon: 'fa fa-truck' },
        { icon: 'fa fa-tty' },
        { icon: 'fa fa-tv' },
        { icon: 'fa fa-umbrella' },
        { icon: 'fa fa-universal-access' },
        { icon: 'fa fa-university' },
        { icon: 'fa fa-unlock' },
        { icon: 'fa fa-unlock-alt' },
        { icon: 'fa fa-unsorted' },
        { icon: 'fa fa-upload' },
        { icon: 'fa fa-user' },
        { icon: 'fa fa-user-circle' },
        { icon: 'fa fa-user-circle-o' },
        { icon: 'fa fa-user-o' },
        { icon: 'fa fa-user-plus' },
        { icon: 'fa fa-user-secret' },
        { icon: 'fa fa-user-times' },
        { icon: 'fa fa-users' },
        { icon: 'fa fa-vcard' },
        { icon: 'fa fa-vcard-o' },
        { icon: 'fa fa-video-camera' },
        { icon: 'fa fa-volume-control-phone' },
        { icon: 'fa fa-volume-down' },
        { icon: 'fa fa-volume-off' },
        { icon: 'fa fa-volume-up' },
        { icon: 'fa fa-warning' },
        { icon: 'fa fa-wheelchair' },
        { icon: 'fa fa-wheelchair-alt' },
        { icon: 'fa fa-window-close' },
        { icon: 'fa fa-window-close-o' },
        { icon: 'fa fa-window-maximize' },
        { icon: 'fa fa-window-minimize' },
        { icon: 'fa fa-window-restore' },
        { icon: 'fa fa-wifi' },
        { icon: 'fa fa-wrenc' },
      ],
      backupIconData: [
        { icon: 'fa fa-address-book' },
        { icon: 'fa fa-address-book-o' },
        { icon: 'fa fa-address-card' },
        { icon: 'fa fa-address-card-o' },
        { icon: 'fa fa-adjust' },
        { icon: 'fa fa-american-sign-language-interpreting' },
        { icon: 'fa fa-anchor' },
        { icon: 'fa fa-archive' },
        { icon: 'fa fa-area-chart' },
        { icon: 'fa fa-arrows' },
        { icon: 'fa fa-arrows-h' },
        { icon: 'fa fa-arrows-v' },
        { icon: 'fa fa-asl-interpreting' },
        { icon: 'fa fa-assistive-listening-systems' },
        { icon: 'fa fa-asterisk' },
        { icon: 'fa fa-at' },
        { icon: 'fa fa-automobile' },
        { icon: 'fa fa-audio-description' },
        { icon: 'fa fa-balance-scale' },
        { icon: 'fa fa-ban' },
        { icon: 'fa fa-bank' },
        { icon: 'fa fa-bar-chart' },
        { icon: 'fa fa-bar-chart-o' },
        { icon: 'fa fa-barcode' },
        { icon: 'fa fa-bars' },
        { icon: 'fa fa-bath' },
        { icon: 'fa fa-bathtub' },
        { icon: 'fa fa-battery-0' },
        { icon: 'fa fa-battery-1' },
        { icon: 'fa fa-battery-2' },
        { icon: 'fa fa-battery-3' },
        { icon: 'fa fa-battery-4' },
        { icon: 'fa fa-battery-empty' },
        { icon: 'fa fa-battery-full' },
        { icon: 'fa fa-battery-half' },
        { icon: 'fa fa-battery-quarter' },
        { icon: 'fa fa-battery-three-quarters' },
        { icon: 'fa fa-bed' },
        { icon: 'fa fa-beer' },
        { icon: 'fa fa-bell' },
        { icon: 'fa fa-bell-o' },
        { icon: 'fa fa-bell-slash' },
        { icon: 'fa fa-bell-slash-o' },
        { icon: 'fa fa-bicycle' },
        { icon: 'fa fa-binoculars' },
        { icon: 'fa fa-birthday-cake' },
        { icon: 'fa fa-blind' },
        { icon: 'fa fa-bolt' },
        { icon: 'fa fa-bomb' },
        { icon: 'fa fa-book' },
        { icon: 'fa fa-bookmark' },
        { icon: 'fa fa-bookmark-o' },
        { icon: 'fa fa-braille' },
        { icon: 'fa fa-briefcase' },
        { icon: 'fa fa-bug' },
        { icon: 'fa fa-building' },
        { icon: 'fa fa-building-o' },
        { icon: 'fa fa-bullhorn' },
        { icon: 'fa fa-bullseye' },
        { icon: 'fa fa-bus' },
        { icon: 'fa fa-cab' },
        { icon: 'fa fa-calculator' },
        { icon: 'fa fa-calendar' },
        { icon: 'fa fa-calendar-o' },
        { icon: 'fa fa-calendar-check-o' },
        { icon: 'fa fa-calendar-minus-o' },
        { icon: 'fa fa-calendar-plus-o' },
        { icon: 'fa fa-calendar-times-o' },
        { icon: 'fa fa-camera' },
        { icon: 'fa fa-camera-retro' },
        { icon: 'fa fa-car' },
        { icon: 'fa fa-caret-square-o-down' },
        { icon: 'fa fa-caret-square-o-left' },
        { icon: 'fa fa-caret-square-o-right' },
        { icon: 'fa fa-caret-square-o-up' },
        { icon: 'fa fa-cart-arrow-down' },
        { icon: 'fa fa-cart-plus' },
        { icon: 'fa fa-cc' },
        { icon: 'fa fa-certificate' },
        { icon: 'fa fa-check' },
        { icon: 'fa fa-check-circle' },
        { icon: 'fa fa-check-circle-o' },
        { icon: 'fa fa-check-square' },
        { icon: 'fa fa-check-square-o' },
        { icon: 'fa fa-child' },
        { icon: 'fa fa-circle' },
        { icon: 'fa fa-circle-o' },
        { icon: 'fa fa-circle-o-notch' },
        { icon: 'fa fa-circle-thin' },
        { icon: 'fa fa-clock-o' },
        { icon: 'fa fa-clone' },
        { icon: 'fa fa-close' },
        { icon: 'fa fa-cloud' },
        { icon: 'fa fa-cloud-download' },
        { icon: 'fa fa-cloud-upload' },
        { icon: 'fa fa-code' },
        { icon: 'fa fa-code-fork' },
        { icon: 'fa fa-coffee' },
        { icon: 'fa fa-cog' },
        { icon: 'fa fa-cogs' },
        { icon: 'fa fa-comment' },
        { icon: 'fa fa-comment-o' },
        { icon: 'fa fa-comments' },
        { icon: 'fa fa-comments-o' },
        { icon: 'fa fa-commenting' },
        { icon: 'fa fa-commenting-o' },
        { icon: 'fa fa-compass' },
        { icon: 'fa fa-copyright' },
        { icon: 'fa fa-credit-card' },
        { icon: 'fa fa-credit-card-alt' },
        { icon: 'fa fa-creative-commons' },
        { icon: 'fa fa-crop' },
        { icon: 'fa fa-crosshairs' },
        { icon: 'fa fa-cube' },
        { icon: 'fa fa-cubes' },
        { icon: 'fa fa-cutlery' },
        { icon: 'fa fa-dashboard' },
        { icon: 'fa fa-database' },
        { icon: 'fa fa-deaf' },
        { icon: 'fa fa-deafness' },
        { icon: 'fa fa-desktop' },
        { icon: 'fa fa-diamond' },
        { icon: 'fa fa-dot-circle-o' },
        { icon: 'fa fa-download' },
        { icon: 'fa fa-drivers-license' },
        { icon: 'fa fa-drivers-license-o' },
        { icon: 'fa fa-edit' },
        { icon: 'fa fa-ellipsis-h' },
        { icon: 'fa fa-ellipsis-v' },
        { icon: 'fa fa-envelope' },
        { icon: 'fa fa-envelope-o' },
        { icon: 'fa fa-envelope-open' },
        { icon: 'fa fa-envelope-open-o' },
        { icon: 'fa fa-envelope-square' },
        { icon: 'fa fa-eraser' },
        { icon: 'fa fa-exchange' },
        { icon: 'fa fa-exclamation' },
        { icon: 'fa fa-exclamation-circle' },
        { icon: 'fa fa-exclamation-triangle' },
        { icon: 'fa fa-external-link' },
        { icon: 'fa fa-external-link-square' },
        { icon: 'fa fa-eye' },
        { icon: 'fa fa-eye-slash' },
        { icon: 'fa fa-eyedropper' },
        { icon: 'fa fa-fax' },
        { icon: 'fa fa-female' },
        { icon: 'fa fa-fighter-jet' },
        { icon: 'fa fa-file-archive-o' },
        { icon: 'fa fa-file-audio-o' },
        { icon: 'fa fa-file-code-o' },
        { icon: 'fa fa-file-excel-o' },
        { icon: 'fa fa-file-image-o' },
        { icon: 'fa fa-file-movie-o' },
        { icon: 'fa fa-file-pdf-o' },
        { icon: 'fa fa-file-photo-o' },
        { icon: 'fa fa-file-picture-o' },
        { icon: 'fa fa-file-powerpoint-o' },
        { icon: 'fa fa-file-sound-o' },
        { icon: 'fa fa-file-video-o' },
        { icon: 'fa fa-file-word-o' },
        { icon: 'fa fa-file-zip-o' },
        { icon: 'fa fa-film' },
        { icon: 'fa fa-filter' },
        { icon: 'fa fa-fire' },
        { icon: 'fa fa-fire-extinguisher' },
        { icon: 'fa fa-flag' },
        { icon: 'fa fa-flag-checkered' },
        { icon: 'fa fa-flag-o' },
        { icon: 'fa fa-flash' },
        { icon: 'fa fa-flask' },
        { icon: 'fa fa-folder' },
        { icon: 'fa fa-folder-o' },
        { icon: 'fa fa-folder-open' },
        { icon: 'fa fa-folder-open-o' },
        { icon: 'fa fa-frown-o' },
        { icon: 'fa fa-futbol-o' },
        { icon: 'fa fa-gamepad' },
        { icon: 'fa fa-gavel' },
        { icon: 'fa fa-gear' },
        { icon: 'fa fa-gears' },
        { icon: 'fa fa-genderless' },
        { icon: 'fa fa-gift' },
        { icon: 'fa fa-glass' },
        { icon: 'fa fa-globe' },
        { icon: 'fa fa-graduation-cap' },
        { icon: 'fa fa-group' },
        { icon: 'fa fa-hard-of-hearing' },
        { icon: 'fa fa-hdd-o' },
        { icon: 'fa fa-handshake-o' },
        { icon: 'fa fa-hashtag' },
        { icon: 'fa fa-headphones' },
        { icon: 'fa fa-heart' },
        { icon: 'fa fa-heart-o' },
        { icon: 'fa fa-heartbeat' },
        { icon: 'fa fa-history' },
        { icon: 'fa fa-home' },
        { icon: 'fa fa-hotel' },
        { icon: 'fa fa-hourglass' },
        { icon: 'fa fa-hourglass-1' },
        { icon: 'fa fa-hourglass-2' },
        { icon: 'fa fa-hourglass-3' },
        { icon: 'fa fa-hourglass-end' },
        { icon: 'fa fa-hourglass-half' },
        { icon: 'fa fa-hourglass-o' },
        { icon: 'fa fa-hourglass-start' },
        { icon: 'fa fa-i-cursor' },
        { icon: 'fa fa-id-badge' },
        { icon: 'fa fa-id-card' },
        { icon: 'fa fa-id-card-o' },
        { icon: 'fa fa-image' },
        { icon: 'fa fa-inbox' },
        { icon: 'fa fa-industry' },
        { icon: 'fa fa-info' },
        { icon: 'fa fa-info-circle' },
        { icon: 'fa fa-institution' },
        { icon: 'fa fa-key' },
        { icon: 'fa fa-keyboard-o' },
        { icon: 'fa fa-language' },
        { icon: 'fa fa-laptop' },
        { icon: 'fa fa-leaf' },
        { icon: 'fa fa-legal' },
        { icon: 'fa fa-lemon-o' },
        { icon: 'fa fa-level-down' },
        { icon: 'fa fa-level-up' },
        { icon: 'fa fa-life-bouy' },
        { icon: 'fa fa-life-buoy' },
        { icon: 'fa fa-life-ring' },
        { icon: 'fa fa-life-saver' },
        { icon: 'fa fa-lightbulb-o' },
        { icon: 'fa fa-line-chart' },
        { icon: 'fa fa-location-arrow' },
        { icon: 'fa fa-lock' },
        { icon: 'fa fa-low-vision' },
        { icon: 'fa fa-magic' },
        { icon: 'fa fa-magnet' },
        { icon: 'fa fa-mail-forward' },
        { icon: 'fa fa-mail-reply' },
        { icon: 'fa fa-mail-reply-all' },
        { icon: 'fa fa-male' },
        { icon: 'fa fa-map' },
        { icon: 'fa fa-map-o' },
        { icon: 'fa fa-map-pin' },
        { icon: 'fa fa-map-signs' },
        { icon: 'fa fa-map-marker' },
        { icon: 'fa fa-meh-o' },
        { icon: 'fa fa-microchip' },
        { icon: 'fa fa-microphone' },
        { icon: 'fa fa-microphone-slash' },
        { icon: 'fa fa-minus' },
        { icon: 'fa fa-minus-circle' },
        { icon: 'fa fa-minus-square' },
        { icon: 'fa fa-minus-square-o' },
        { icon: 'fa fa-mobile' },
        { icon: 'fa fa-mobile-phone' },
        { icon: 'fa fa-money' },
        { icon: 'fa fa-moon-o' },
        { icon: 'fa fa-mortar-board' },
        { icon: 'fa fa-motorcycle' },
        { icon: 'fa fa-mouse-pointer' },
        { icon: 'fa fa-music' },
        { icon: 'fa fa-navicon' },
        { icon: 'fa fa-newspaper-o' },
        { icon: 'fa fa-object-group' },
        { icon: 'fa fa-object-ungroup' },
        { icon: 'fa fa-paint-brush' },
        { icon: 'fa fa-paper-plane' },
        { icon: 'fa fa-paper-plane-o' },
        { icon: 'fa fa-paw' },
        { icon: 'fa fa-pencil' },
        { icon: 'fa fa-pencil-square' },
        { icon: 'fa fa-pencil-square-o' },
        { icon: 'fa fa-percent' },
        { icon: 'fa fa-phone' },
        { icon: 'fa fa-phone-square' },
        { icon: 'fa fa-photo' },
        { icon: 'fa fa-picture-o' },
        { icon: 'fa fa-pie-chart' },
        { icon: 'fa fa-plane' },
        { icon: 'fa fa-plug' },
        { icon: 'fa fa-plus' },
        { icon: 'fa fa-plus-circle' },
        { icon: 'fa fa-plus-square' },
        { icon: 'fa fa-plus-square-o' },
        { icon: 'fa fa-podcast' },
        { icon: 'fa fa-power-off' },
        { icon: 'fa fa-print' },
        { icon: 'fa fa-puzzle-piece' },
        { icon: 'fa fa-qrcode' },
        { icon: 'fa fa-question' },
        { icon: 'fa fa-question-circle' },
        { icon: 'fa fa-question-circle-o' },
        { icon: 'fa fa-quote-left' },
        { icon: 'fa fa-quote-right' },
        { icon: 'fa fa-random' },
        { icon: 'fa fa-recycle' },
        { icon: 'fa fa-refresh' },
        { icon: 'fa fa-registered' },
        { icon: 'fa fa-remove' },
        { icon: 'fa fa-reorder' },
        { icon: 'fa fa-reply' },
        { icon: 'fa fa-reply-all' },
        { icon: 'fa fa-retweet' },
        { icon: 'fa fa-road' },
        { icon: 'fa fa-rocket' },
        { icon: 'fa fa-rss' },
        { icon: 'fa fa-rss-square' },
        { icon: 'fa fa-s15' },
        { icon: 'fa fa-search' },
        { icon: 'fa fa-search-minus' },
        { icon: 'fa fa-search-plus' },
        { icon: 'fa fa-send' },
        { icon: 'fa fa-send-o' },
        { icon: 'fa fa-server' },
        { icon: 'fa fa-share' },
        { icon: 'fa fa-share-alt' },
        { icon: 'fa fa-share-alt-square' },
        { icon: 'fa fa-share-square' },
        { icon: 'fa fa-share-square-o' },
        { icon: 'fa fa-shield' },
        { icon: 'fa fa-ship' },
        { icon: 'fa fa-shopping-bag' },
        { icon: 'fa fa-shopping-basket' },
        { icon: 'fa fa-shopping-cart' },
        { icon: 'fa fa-shower' },
        { icon: 'fa fa-sign-in' },
        { icon: 'fa fa-sign-out' },
        { icon: 'fa fa-sign-language' },
        { icon: 'fa fa-signal' },
        { icon: 'fa fa-signing' },
        { icon: 'fa fa-sitemap' },
        { icon: 'fa fa-sliders' },
        { icon: 'fa fa-smile-o' },
        { icon: 'fa fa-snowflake-o' },
        { icon: 'fa fa-soccer-ball-o' },
        { icon: 'fa fa-sort' },
        { icon: 'fa fa-sort-alpha-asc' },
        { icon: 'fa fa-sort-alpha-desc' },
        { icon: 'fa fa-sort-amount-asc' },
        { icon: 'fa fa-sort-amount-desc' },
        { icon: 'fa fa-sort-asc' },
        { icon: 'fa fa-sort-desc' },
        { icon: 'fa fa-sort-down' },
        { icon: 'fa fa-sort-numeric-asc' },
        { icon: 'fa fa-sort-numeric-desc' },
        { icon: 'fa fa-sort-up' },
        { icon: 'fa fa-space-shuttle' },
        { icon: 'fa fa-spinner' },
        { icon: 'fa fa-spoon' },
        { icon: 'fa fa-square' },
        { icon: 'fa fa-square-o' },
        { icon: 'fa fa-star' },
        { icon: 'fa fa-star-half' },
        { icon: 'fa fa-star-half-empty' },
        { icon: 'fa fa-star-half-full' },
        { icon: 'fa fa-star-half-o' },
        { icon: 'fa fa-star-o' },
        { icon: 'fa fa-sticky-note' },
        { icon: 'fa fa-sticky-note-o' },
        { icon: 'fa fa-street-view' },
        { icon: 'fa fa-suitcase' },
        { icon: 'fa fa-sun-o' },
        { icon: 'fa fa-support' },
        { icon: 'fa fa-tablet' },
        { icon: 'fa fa-tachometer' },
        { icon: 'fa fa-tag' },
        { icon: 'fa fa-tags' },
        { icon: 'fa fa-tasks' },
        { icon: 'fa fa-taxi' },
        { icon: 'fa fa-television' },
        { icon: 'fa fa-terminal' },
        { icon: 'fa fa-thermometer' },
        { icon: 'fa fa-thermometer-0' },
        { icon: 'fa fa-thermometer-1' },
        { icon: 'fa fa-thermometer-2' },
        { icon: 'fa fa-thermometer-3' },
        { icon: 'fa fa-thermometer-4' },
        { icon: 'fa fa-thermometer-empty' },
        { icon: 'fa fa-thermometer-full' },
        { icon: 'fa fa-thermometer-half' },
        { icon: 'fa fa-thermometer-quarter' },
        { icon: 'fa fa-thermometer-three-quarters' },
        { icon: 'fa fa-thumb-tack' },
        { icon: 'fa fa-thumbs-down' },
        { icon: 'fa fa-thumbs-o-up' },
        { icon: 'fa fa-thumbs-up' },
        { icon: 'fa fa-ticket' },
        { icon: 'fa fa-times' },
        { icon: 'fa fa-times-circle' },
        { icon: 'fa fa-times-circle-o' },
        { icon: 'fa fa-times-rectangle' },
        { icon: 'fa fa-times-rectangle-o' },
        { icon: 'fa fa-tint' },
        { icon: 'fa fa-toggle-down' },
        { icon: 'fa fa-toggle-left' },
        { icon: 'fa fa-toggle-right' },
        { icon: 'fa fa-toggle-up' },
        { icon: 'fa fa-toggle-off' },
        { icon: 'fa fa-toggle-on' },
        { icon: 'fa fa-trademark' },
        { icon: 'fa fa-trash' },
        { icon: 'fa fa-trash-o' },
        { icon: 'fa fa-tree' },
        { icon: 'fa fa-trophy' },
        { icon: 'fa fa-truck' },
        { icon: 'fa fa-tty' },
        { icon: 'fa fa-tv' },
        { icon: 'fa fa-umbrella' },
        { icon: 'fa fa-universal-access' },
        { icon: 'fa fa-university' },
        { icon: 'fa fa-unlock' },
        { icon: 'fa fa-unlock-alt' },
        { icon: 'fa fa-unsorted' },
        { icon: 'fa fa-upload' },
        { icon: 'fa fa-user' },
        { icon: 'fa fa-user-circle' },
        { icon: 'fa fa-user-circle-o' },
        { icon: 'fa fa-user-o' },
        { icon: 'fa fa-user-plus' },
        { icon: 'fa fa-user-secret' },
        { icon: 'fa fa-user-times' },
        { icon: 'fa fa-users' },
        { icon: 'fa fa-vcard' },
        { icon: 'fa fa-vcard-o' },
        { icon: 'fa fa-video-camera' },
        { icon: 'fa fa-volume-control-phone' },
        { icon: 'fa fa-volume-down' },
        { icon: 'fa fa-volume-off' },
        { icon: 'fa fa-volume-up' },
        { icon: 'fa fa-warning' },
        { icon: 'fa fa-wheelchair' },
        { icon: 'fa fa-wheelchair-alt' },
        { icon: 'fa fa-window-close' },
        { icon: 'fa fa-window-close-o' },
        { icon: 'fa fa-window-maximize' },
        { icon: 'fa fa-window-minimize' },
        { icon: 'fa fa-window-restore' },
        { icon: 'fa fa-wifi' },
        { icon: 'fa fa-wrenc' },
      ],
    }
  },
  methods: {
    moment,
    switchChangesValue(x, input, reqretval) {
      // console.log('x', x)
      // console.log('input', input)
      // console.log('reqretval', reqretval)
      if (reqretval === '01') {
        this.modalInput[input] = x ? 1 : 0
        // console.log('this.modalInput[input]01', this.modalInput[input])
      } else {
        this.modalInput[input] = x ? 'true' : 'false'
        // console.log('this.modalInput[input]', this.modalInput[input])
      }
    },
    async getAllData() {
      this.mode = 'netral'
      var imbuhan = this.dbname !== '' && this.tablename !== '' ? '?dbname=' + this.dbname + '&table_name=' + this.tablename : ''
      var res = await lou.readMaster('manage/gridconfig' + imbuhan, false, true)
      if (res) {
        this.rowData = res.data
        this.backupRowData = this.$g.clone(this.rowData)
        this.rowAfterFilter = this.$g.clone(this.rowData)
        this.backupTreeData = this.$g.clone(res.data)
        this.treeData = res.data
      }
    },
    showFormModal() {
      if (this.dbname !== '' && this.tablename !== '') {
        this.$refs.myform.showModal(this.dbname, this.tablename, this.rowData)
      }
    },
    removeOrDelete() {
      // console.log('this.selectedData', this.selectedData)
      if (this.selectedData === null) {
        lou.shownotif(
          'Anda belum memilih data atau data yang anda pilih harus tidak boleh lebih dari 1',
        )
      } else {
        if (this.selectedData[0].newdata) {
          var indexTarget = this.rowData.findIndex(
            (x) => x.id === this.selectedData[0].id,
          )
          this.rowData.splice(indexTarget, 1)
        } else {
          this.confirmationDelete(this.selectedData[0].id)
          // send to api
        }
      }
    },
    cancelaction() {
      // console.log('this.mode', this.mode)
      if (this.mode === 'oncreate') {
        var maped = this.rowData.filter((x) => x.newdata === undefined)
        // console.log('maped', maped)
        this.rowData = maped
      } else {
        // var changedRowNode = []
        this.rowData = this.$g.clone(this.backupRowData)
        this.treeData = this.$g.clone(this.backupTreeData)
      }
      this.mode = 'netral'
      this.changedData = []
      this.input.undoInput = 0
      this.input.undoInputdisable = true

      this.input.redoInput = 0
      this.input.redoInputdisable = true
      this.refreshTable()
      // for (let i = 0; i < this.input.undoInput; i++) {
      //   this.gridApi.undoCellEditing()
      // }
    },
    async confirmationDelete(id) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await lou.customUrlDelete('manage/gridconfig/' + this.dbname + '/' + this.tablename + '/' + id)
          this.getAllData()
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    async getTableList() {
      var res = await lou.readMaster('manage/gridconfig/table_list?dbName=' + this.dbname, true, true)
      if (res) {
        this.tablelist = res.data
        var tablelist = []
        for (let i = 0; i < this.tablelist.length; i++) {
          const element = this.tablelist[i]
          // console.log('element', element)
          tablelist.push({ kode: element.table_name, keterangan: element.table_name })
        }
        // this.columnDefs[1].cellEditorParams.values = tablelist
        // console.log('this.columnDefs[1].cellEditorParams.values', this.columnDefs[1].cellEditorParams.values)
      }
    },
    selectIcon(record) {
      this.modalInput.icon = record.icon
      this.selectIconModalVisible = false
      this.iconModalSearchInput = ''
      this.iconData = this.$g.clone(this.backupIconData)
    },
    filterIcon() {
      var dic = this.$g.clone(this.backupIconData)
      this.iconData = dic.filter(x => x.icon.includes(this.iconModalSearchInput))
    },
    showModal(mode = 'create', id) {
      // console.log('this.pagesList', this.pagesList)
      // console.log('id', id)
      this.formvisible = true
      if (mode === 'create') {
        this.modalInput = {
          table_name: this.tablename,
          field: '',
          headerName: '',
          editable: 'true',
          cellEditor: null,
          valueFormatter: null,
          data_type: 'varchar',
          reftable: null,
          retreffield: null,
          filter: 'true',
          resizable: 'true',
          sortable: 'true',
          pinned: 'false',
          minWidth: null,
          maxWidth: null,
          width: null,
          isHide: '',
          rules: '',
          cellRenderer: '',
          type: '',
          allowNull: 1,
          urutan: '',
        }
        this.modalaction = 'create'
      } else if (mode === 'update') {
        var tindex = this.treeData.findIndex(x => x.id === id)
        // console.log('tindex', tindex)
        var tr = this.treeData[tindex]
        // console.log('tr', tr)
        this.modalInput = {
          ...tr,
        }
        // console.log('tr', tr)
        // console.log('this.modalInput.isexclude', this.modalInput.isexclude)
        this.modalaction = 'update'
      } else {
        // var tindex1 = this.pagesList.findIndex(x => x.id === id)
        // console.log('tindex1', tindex1)
        // var tr1 = this.pagesList[tindex1]
        this.modalInput = {
          table_name: this.tablename,
          field: '',
          headerName: '',
          editable: 'true',
          cellEditor: null,
          valueFormatter: null,
          data_type: 'varchar',
          reftable: null,
          retreffield: null,
          filter: 'true',
          resizable: 'true',
          sortable: 'true',
          pinned: 'false',
          minWidth: null,
          maxWidth: null,
          width: null,
          isHide: '',
          rules: '',
          cellRenderer: '',
          type: '',
          allowNull: 1,
          urutan: '',
        }
        this.modalaction = 'create'
      }
    },
    handleCancel() {
      this.formvisible = false
      this.modalInput = {
        table_name: this.tablename,
        field: '',
        headerName: '',
        editable: 'true',
        cellEditor: null,
        valueFormatter: null,
        data_type: 'varchar',
        reftable: null,
        retreffield: null,
        filter: 'true',
        resizable: 'true',
        sortable: 'true',
        pinned: 'false',
        minWidth: '',
        maxWidth: '',
        width: '',
        isHide: '',
        rules: '',
        cellRenderer: '',
        type: '',
        allowNull: 1,
        urutan: '',
      }
    },
    onContextMenuClick(treeKey, menuKey) {
      // console.log(`treeKey: ${treeKey}, menuKey: ${menuKey}`)
    },
    async handleSubmit() {
      var fd = [
        this.$g.clone(this.modalInput),
      ]
      fd[0].allowNull = this.modalInput.allowNull === null ? 0 : 1
      // console.log('fd', fd)
      var res = {}
      // if (this.modalaction === 'create') {
      //   // console.log('fd', fd)
      //   res = await lou.customUrlPost2('manage/gridconfig', fd, true, true)
      // } else {
      res = await lou.customUrlPut2('manage/gridconfig/many?dbName=' + this.dbname + '&table_name=' + this.tablename, fd, true, true)
      // console.log('fd', fd)
      // }
      if (res) {
        this.handleCancel()
        this.getData()
      }
    },
    async getMaster() {
      var allmaster = {}
      var cabanglist = []
      var tablelist = []
      var applist = await lou.customUrlGet('manage/app', false, true)
      if (applist) {
        this.applist = applist.data
        this.dblist = []
        this.applist.forEach((ele) => {
          var targetIndex = this.dblist.findIndex(x => x.dbname === ele.dbname)
          if (targetIndex < 0) {
            var data = {
              id: ele.id,
              appname: ele.appName,
              appkey: ele.appKey,
              dbname: ele.dbname,
            }
            this.dblist.push(data)
          }
        })
        // console.log('this.dblist', this.dblist)
        // console.log('this.applist', this.applist)
      }
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      for (let i = 0; i < this.tablelist.length; i++) {
        const element = this.tablelist[i]
        // console.log('element', element)
        tablelist.push({ kode: element.table_name, keterangan: element.table_name })
      }
      allmaster.cabanglist = cabanglist
      allmaster.tablelist = tablelist
      this.columnDefs[1].cellEditorParams.values = tablelist
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      // this.getColumnDefs()
    },
    async getData() {
      var imbuhan = this.dbname !== '' && this.tablename !== '' ? '?dbname=' + this.dbname + '&table_name=' + this.tablename : ''
      imbuhan += this.appname !== '' ? '&appname=' + this.appname : ''
      var res = await lou.readMaster('manage/gridconfig' + imbuhan, false, true)
      this.treeData = res.data
    },

    onDrop(info) {
      const dropKey = info.node.eventKey
      const dragKey = info.dragNode.eventKey
      const dropPos = info.node.pos.split('-')
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

      // console.log('Drag Key:', dragKey)
      // console.log('Drop Key:', dropKey)
      // console.log('Drop Position:', dropPosition)

      const data = [...this.treeData]

      // Helper function to find and remove the dragged node
      const findAndRemove = (data, key) => {
        let dragObj
        data.forEach((item, index, arr) => {
          if (item.id === key) {
            // console.log(`Removing node with id: ${key} at index: ${index}`)
            dragObj = item
            arr.splice(index, 1)
          } else if (item.children) {
            const result = findAndRemove(item.children, key)
            if (result) dragObj = result
          }
        })
        // console.log('Dragged Object:', dragObj)
        return dragObj
      }

      const dragObj = findAndRemove(data, dragKey)

      if (!dragObj) {
        console.error('Dragged object not found! Possible issue with the key.')
        return
      }

      if (!info.dropToGap) {
        // Dropped on the node itself; insert as a sibling below
        const addAsSiblingBelow = (data, key, dragObj) => {
          data.forEach((item, index, arr) => {
            if (item.id === key) {
              arr.splice(index + 1, 0, dragObj) // Insert below the target node
            } else if (item.children) {
              addAsSiblingBelow(item.children, key, dragObj)
            }
          })
        }

        addAsSiblingBelow(data, dropKey, dragObj)
      } else {
        // Helper function to insert the dragged node at the specified position
        const insertAtPosition = (data, key, dragObj, position) => {
          let inserted = false
          data.forEach((item, index, arr) => {
            if (item.id === key && !inserted) {
              if (position === -1) {
                // console.log(`Inserting above node with id: ${key}`)
                arr.splice(index, 0, dragObj) // Insert above
              } else {
                // console.log(`Inserting below node with id: ${key}`)
                arr.splice(index + 1, 0, dragObj) // Insert below
              }
              inserted = true
              // console.log('Array after insertion:', arr)
            } else if (item.children) {
              insertAtPosition(item.children, key, dragObj, position)
            }
          })
        }

        insertAtPosition(data, dropKey, dragObj, dropPosition)
      }

      // console.log('Final Tree Data:', JSON.stringify(data, null, 2))

      this.treeData = data
      this.mode = 'onupdate'
    },

    onDragEnter(info) {
      // console.log('info', info)
    },
    testarrangePermissions() {
      this.modalVisible = true
      // this.arrangePermissions()
      // console.log('this.modifiedTreeData', this.modifiedTreeData)
      // this.modifiedTreeData.forEach((element, index) => {
      //   // console.log(index, element.urutan)
      // })
    },
    arrangePermissions() {
      var npermissions = this.$g.clone(this.treeData)
      var sortchildren = (data, parentid) => {
        data.forEach((element, index) => {
          var nurutan = index + 1
          element.parent = parentid
          element.urutan = nurutan
          if (
            element.children !== undefined &&
            element.children !== null &&
            element.children.length !== 0
          ) {
            var nchildren = sortchildren(element.children, element.id)
            element.children = nchildren
          }
        })
        return data
      }
      npermissions.forEach((element, index) => {
        var nurutan = index + 1
        element.parent = 0
        element.urutan = nurutan
        if (element.children !== null && element.children !== undefined) {
          if (element.children.length !== 0) {
            var nchildren = sortchildren(element.children, element.id)
            element.children = nchildren
          }
        }
      })
      this.modifiedTreeData = this.$g.clone(npermissions)
    },
    arrangePermissionsClone() {
      var npermissions = this.$g.clone(this.treeData)
      var apptarget = this.dataapplist.findIndex(x => x.id === this.tujuanappid)
      var sortchildren = (data, parentid) => {
        data.forEach((element, index) => {
          var nurutan = index + 1
          element.parent = parentid
          element.urutan = nurutan
          var appname = this.dataapplist[apptarget].appName
          appname = appname.replaceAll(' ', '')
          var newkey = appname.toLowerCase() + '-' + element.key
          element.key = newkey
          // console.log('element.key', element.key)
          if (
            element.children !== undefined &&
            element.children !== null &&
            element.children.length !== 0
          ) {
            var nchildren = sortchildren(element.children, element.id)
            element.children = nchildren
          }
        })
        return data
      }
      npermissions.forEach((element, index) => {
        var nurutan = index + 1
        element.parent = 0
        element.urutan = nurutan
        var appname = this.dataapplist[apptarget].appName
        appname = appname.replaceAll(' ', '')
        var newkey = appname.toLowerCase() + '-' + element.key
        element.key = newkey
        // console.log('element.key', element.key)
        if (element.children !== null && element.children !== undefined) {
          if (element.children.length !== 0) {
            var nchildren = sortchildren(element.children, element.id)
            element.children = nchildren
          }
        }
      })
      this.modifiedTreeDataClone = this.$g.clone(npermissions)
    },
    async saveChanges() {
      this.arrangePermissions()
      if (this.showjsonincl) {
        // console.log('JSON', JSON.stringify(this.modifiedTreeData))
      }
      // console.log('this.modifiedTreeData', this.modifiedTreeData)
      // var res = await lou.customUrlPut('manage/gridconfig/' + this.dbname + '/' + this.tablename + '/many', this.modifiedTreeData, true, true)
      var res = await lou.customUrlPut('manage/gridconfig/many?dbName=' + this.dbname + '&table_name=' + this.tablename, this.modifiedTreeData, true, true)
      if (res) {
        this.getData()
        this.mode = 'netral'
      }
    },
    async cloneAppData() {
      this.arrangePermissionsClone()
      if (this.showjsonincl) {
        // console.log('this.modifiedTreeData', JSON.stringify(this.modifiedTreeDataClone))
      }
      // var res = await lou.customUrlPost('manage/arrange/clone/' + this.tujuanappid, this.modifiedTreeDataClone, true, true)
      // if (res) {
      //   this.modalVisible = false
      //   this.appId = this.tujuanappid
      //   this.getData()
      //   this.tujuanappid = null
      // }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style></style>
